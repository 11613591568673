import React from 'react'
import interests from '../images/interests.png'

export default function Interests() {
  return (
    <div>
      <div>
        <img className="bg-indigo-300" src={interests} alt="about me in photos"/>
      </div>
    </div>
  )
}
